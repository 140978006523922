<template>

  <!--
  <Message severity="success">Success Message Content</Message>
  <OrderList v-model="items" listStyle="height:auto" dataKey="id"
    :stripedRows=true
  >
    <template #controlstart>
      <div/>
    </template>
    <template #item="slotProps">
        <div class="conversation-item">
            <img :src="'demo/images/car/' + slotProps.item.brand + '.png'">
            <div>
                <span class="p-caritem-vin">{{slotProps.item.vin}}</span>
                <span>{{slotProps.item.year}} - {{slotProps.item.color}}</span>
            </div>
        </div>
    </template>
  </OrderList>
  -->
  <Listbox :options="entities" optionLabel="name">
    <template #option="slotProps">
      <div :class="'conversation-item' + slotProps.index%2">
        <div class="info">
          <span class="date">{{formatter.formatDate(slotProps.option.createdDate, pattern)}}</span>
          <span class="sender">{{slotProps.option.createdUser}}</span>
        </div>
        <span class="message">{{slotProps.option.message}}</span>
      </div>
    </template>
  </Listbox>
</template>

<script>
  import formatter from '@/utils/formatter';
  import { Consts } from '@/utils/consts';
  export default {
    name: "Conversation",
    components: {},
    props: {
      entities : {
        type: Array,
        default() {
          return []
        }
      },
    },
    data() {
      console.log(this.entities);
      return {
        items: this.entities,
        selectedItem: null,
        formatter,
        pattern: Consts.FORMAT_DATE_TIME_US,

      }
    }
  };
</script>
<style type = "text/css">
.item-style {
  background-color: #e9ecef;
}
.conversation-item0 {
  border-bottom: 1px solid #e9ecef;
  padding-bottom: 5px;
  /*background-color: #e9ecef;*/
}
.conversation-item1 {

}
.info {
  padding-bottom: 2px;
}
.date {
  padding-right: 10px;
  font-style: italic;
}
.sender {
  font-weight: bold
}
</style>
